*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: inline;
}

img, video {
  max-width: 100%;
  width: 100%;
  height: auto;
}

body {
  font-family: Inter, sans-serif;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.ab {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 450px) {
  .ab {
    max-width: 450px;
  }
}

@media (min-width: 575px) {
  .ab {
    max-width: 575px;
  }
}

@media (min-width: 768px) {
  .ab {
    max-width: 768px;
  }
}

@media (min-width: 992px) {
  .ab {
    max-width: 992px;
  }
}

@media (min-width: 1200px) {
  .ab {
    max-width: 1200px;
  }
}

@media (min-width: 1400px) {
  .ab {
    max-width: 1400px;
  }
}

@media (min-width: 1500px) {
  .ab {
    max-width: 1500px;
  }
}

.a1l {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.aw {
  position: fixed;
}

.a3 {
  position: absolute;
}

.ac {
  position: relative;
}

.a4 {
  top: 0;
}

.a5 {
  left: 0;
}

.al {
  right: 1rem;
}

.am\/2 {
  top: 50%;
}

.ax {
  top: 2.5rem;
}

.ay {
  right: 2.5rem;
}

.a1_ {
  bottom: 2rem;
}

.a20 {
  right: 2rem;
}

.a21 {
  left: auto;
}

.a2x {
  bottom: 0;
}

.a2z {
  right: 0;
}

.a39 {
  top: 0;
}

.a3a {
  bottom: 2.5rem;
}

.a3k\/2 {
  bottom: -50%;
}

.a3n {
  right: 1.25rem;
}

.a3q {
  left: 2.5rem;
}

.a3r {
  top: -3rem;
}

.a3w {
  top: 9rem;
}

.a3x {
  top: -7rem;
}

.a3y {
  top: 5rem;
}

.a3V {
  top: -1.25rem;
}

.a3W\[250px\] {
  top: -250px;
}

.a42 {
  top: -6rem;
}

.a43 {
  bottom: -6rem;
}

.a6 {
  z-index: 50;
}

.az\[9999\] {
  z-index: 9999;
}

.az\[999\] {
  z-index: 999;
}

.a2t {
  z-index: 10;
}

.a2y {
  z-index: -10;
}

.a3M {
  z-index: 20;
}

.a1P {
  order: 9999;
}

.a1R {
  order: -9999;
}

.a1w {
  margin-left: auto;
  margin-right: auto;
}

.a1E {
  margin-left: -1rem;
  margin-right: -1rem;
}

.a1O {
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.a2I\[10px\] {
  margin-left: -10px;
  margin-right: -10px;
}

.a3z {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.a3C {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.a2I\[22px\] {
  margin-left: -22px;
  margin-right: -22px;
}

.a4d {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.aB\[2px\] {
  margin-top: -2px;
}

.a1h\[60px\] {
  margin-right: 60px;
}

.a1x {
  margin-bottom: 2rem;
}

.a1z\[18px\] {
  margin-bottom: 18px;
}

.a1z\[30px\] {
  margin-bottom: 30px;
}

.a1J {
  margin-bottom: 2.75rem;
}

.a1JQ {
  margin-bottom: 1rem;
}

.a1S {
  margin-top: 1rem;
}

.a1U {
  margin-bottom: 1rem;
}

.a25\[6px\] {
  margin-top: 6px;
}

.a2a {
  margin-bottom: 2.5rem;
}

.a2d {
  margin-bottom: 1.25rem;
}

.a2g {
  margin-bottom: 2.25rem;
}

.a2h {
  margin-bottom: .5rem;
}

.a2i {
  margin-right: .75rem;
}

.a2m {
  margin-right: .5rem;
}

.a2n {
  margin-bottom: 1.5rem;
}

.a2A {
  margin-bottom: .75rem;
}

.a2N {
  margin-top: .75rem;
}

.a25\[30px\] {
  margin-top: 30px;
}

.a2T {
  margin-bottom: 3rem;
}

.a2_ {
  margin-right: 1.5rem;
}

.a1h\[18px\] {
  margin-right: 18px;
}

.a1h\[22px\] {
  margin-right: 22px;
}

.a3b {
  margin-bottom: 3.5rem;
}

.a1z\[60px\] {
  margin-bottom: 60px;
}

.a3G {
  margin-bottom: 1.75rem;
}

.a1h\[10px\] {
  margin-right: 10px;
}

.a3K {
  margin-top: .25rem;
}

.a3U {
  margin-top: 5rem;
}

.a1z\[50px\] {
  margin-bottom: 50px;
}

.a3Z {
  margin-right: 1rem;
}

.a1z\[10px\] {
  margin-bottom: 10px;
}

.a25\[4px\] {
  margin-top: 4px;
}

.ah {
  display: block;
}

.a1o {
  display: inline-block;
}

.a2V {
  display: inline;
}

.ae {
  display: flex;
}

.aQ {
  display: inline-flex;
}

.ak {
  display: none;
}

.a38 {
  aspect-ratio: 1 / 1;
}

.an {
  height: 2.5rem;
}

.au\[2px\] {
  height: 2px;
}

.aD {
  min-height: 40vh;
}

.aDBlog {
  min-height: 180vh;
  padding-bottom: 30px;
}

.aDNav {
  height: 100vh;
}

.a26 {
  height: .75rem;
}

.a2k {
  height: 100%;
}

.a2B {
  height: 2.25rem;
}

.au\[60px\] {
  height: 60px;
}

.au\[90px\] {
  height: 90px;
}

.au\[256px\], .aum\[256px\] {
  height: 256px;
}

.au\[400px\] {
  height: 400px;
}

.a3o\/2 {
  height: 50%;
}

.au\[34px\] {
  height: 34px;
}

.a3B {
  height: 1.75rem;
}

.au\[56px\] {
  height: 56px;
}

.aI {
  height: auto;
}

.au\[1px\] {
  height: 1px;
}

.au\[22px\] {
  height: 22px;
}

.a7 {
  width: 100%;
}

.ao {
  width: 2.5rem;
}

.av {
  width: 1.75rem;
}

.a1N {
  width: .75rem;
}

.a2F {
  width: 2.25rem;
}

.a13\[60px\] {
  width: 60px;
}

.a13\[90px\] {
  width: 90px;
}

.a13\[400px\] {
  width: 400px;
}

.a13\[256px\] {
  width: 256px;
}

.a13m\[256px\] {
  max-width: 256px;
}

.a1L\/2 {
  width: 50%;
}

.a13\[22px\] {
  width: 22px;
}

.ad\[1400px\] {
  max-width: 1400px;
}

.ad\[145px\] {
  max-width: 145px;
}

.ad\[570px\] {
  max-width: 570px;
}

.a1y {
  max-width: 100%;
}

.ad\[1390px\] {
  max-width: 1390px;
}

.ad\[320px\] {
  max-width: 320px;
}

.ad\[970px\] {
  max-width: 970px;
}

.ad\[770px\] {
  max-width: 770px;
}

.ad\[40px\] {
  max-width: 40px;
}

.ad\[550px\] {
  max-width: 550px;
}

.ad\[475px\] {
  max-width: 475px;
}

.ad\[530px\] {
  max-width: 530px;
}

.ad\[690px\] {
  max-width: 690px;
}

.ad\[310px\] {
  max-width: 310px;
}

.ad\[470px\] {
  max-width: 470px;
}

.ad\[1120px\] {
  max-width: 1120px;
}

.ad\[1000px\] {
  max-width: 1000px;
}

.ad\[252px\] {
  max-width: 252px;
}

.ad\[435px\] {
  max-width: 435px;
}

.ad\[56px\] {
  max-width: 56px;
}

.ad\[785px\] {
  max-width: 785px;
}

.ad\[925px\] {
  max-width: 925px;
}

.ad\[520px\] {
  max-width: 520px;
}

.ad\[70px\] {
  max-width: 70px;
}

.ad\[22px\] {
  max-width: 22px;
}

.ap\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.aA {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.aC {
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.a1k {
  cursor: pointer;
}

.a4f {
  -webkit-user-select: none;
  user-select: none;
}

.a2q {
  list-style-position: inside;
}

.a2r {
  list-style-type: disc;
}

.aq {
  flex-direction: column;
}

.a1F {
  flex-wrap: wrap;
}

.a2L {
  align-items: flex-end;
}

.af {
  align-items: center;
}

.a1i {
  justify-content: flex-end;
}

.ar {
  justify-content: center;
}

.ag {
  justify-content: space-between;
}

.as\[6px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(6px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(6px * var(--tw-space-y-reverse));
}

.aM > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.a_ > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.as\[10px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(10px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(10px * var(--tw-space-y-reverse));
}

.a1V > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.a1Z > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.a2s > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.a2E > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.a3_\[6px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(6px * var(--tw-space-x-reverse));
  margin-left: calc(6px * calc(1 - var(--tw-space-x-reverse)));
}

.a3P {
  align-self: center;
}

.a1v {
  overflow: hidden;
}

.a1p {
  border-radius: .375rem;
}

.a2j {
  border-radius: 100%;
}

.a2u {
  border-radius: .25rem;
}

.a14 {
  border-radius: .5rem;
}

.a3d {
  border-radius: 1rem;
}

.a3i {
  border-radius: 1.5rem;
}

.a3p\[20px\] {
  border-radius: 20px;
}

.a3D {
  border-radius: .75rem;
}

.a4g {
  border-radius: .125rem;
}

.a34 {
  border-width: 2px;
}

.a345 {
  border-width: .5rem;
}

.a3u {
  border-width: 1px;
}

.a33\[\.7px\] {
  border-width: .7px;
}

.a27 {
  border-top-width: 1px;
}

.a28 {
  border-left-width: 1px;
}

.a30 {
  border-right-width: 1px;
}

.a3H {
  border-bottom-width: 1px;
}

.a8 {
  --tw-border-opacity: 1;
  border-color: rgb(235 239 244 / var(--tw-border-opacity));
}

.a29 {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.a35 {
  border-color: currentColor;
}

.a31 {
  --tw-border-opacity: .4;
}

.a1 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.background-cover {
  --tw-bg-opacity: .5rem;
  background-image: linear-gradient(to right, #85e3ff1a, #aff8db33, #fff5ba33);
}

.a2 {
  --tw-bg-opacity: 1;
  background-color: rgb(24 28 49 / var(--tw-bg-opacity));
}

.a1q {
  --tw-bg-opacity: 1;
  background-color: #03c988;
}

.tint {
  position: relative;
}

.tint:after {
  --tw-bg-opacity: 1;
  background-color: rgb(112 131 245 / var(--tw-bg-opacity));
  opacity: .6;
  content: "";
  border-radius: 1rem;
  display: block;
  position: absolute;
  inset: 0;
}

.a1e\[\#F8FAFB\] {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 251 / var(--tw-bg-opacity));
}

.a3j {
  --tw-bg-opacity: 1;
  background-color: rgb(248 249 255 / var(--tw-bg-opacity));
}

.a4a {
  --tw-bg-opacity: 1;
  background-color: rgb(235 239 244 / var(--tw-bg-opacity));
}

.aE {
  --tw-bg-opacity: .95;
}

.a2M {
  background-image: linear-gradient(#0000 50%, #000c 100%);
}

.a2W {
  background-image: linear-gradient(55.15deg, #8ea5fe -7.09%, #beb3fd 51.72%, #90d1ff 101.48%);
}

.a3v {
  background-image: linear-gradient(120.12deg, #ff8fe8 0%, #ffc960 100%);
}

.a2X {
  -webkit-background-clip: text;
  -webkit-background-clip: text;
  background-clip: text;
}

.aZ {
  fill: currentColor;
}

.a2c {
  -o-object-fit: cover;
  object-fit: cover;
}

.a2l {
  -o-object-position: center;
  object-position: center;
}

.a3A\[3px\] {
  padding: 3px;
}

.a48 {
  padding: .5rem;
}

.a49 {
  padding: .75rem;
}

.ai {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.a1m\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}

.a1n {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.a1r\[30px\] {
  padding-left: 30px;
  padding-right: 30px;
}

.a1D {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.a15 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.a1G {
  padding-left: 1rem;
  padding-right: 1rem;
}

.aW {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.a1Q {
  padding-left: .75rem;
  padding-right: .75rem;
}

.a2v {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}

.a1r\[18px\] {
  padding-left: 18px;
  padding-right: 18px;
}

.a1r\[10px\] {
  padding-left: 10px;
  padding-right: 10px;
}

.a1m\[14px\] {
  padding-top: 14px;
  padding-bottom: 14px;
}

.a2K {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.a3E {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.a3F {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.a3N {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.a2S {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.a3X {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.a2J {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.a1m\[110px\] {
  padding-top: 110px;
  padding-bottom: 110px;
}

.a1m\[6px\] {
  padding-top: 6px;
  padding-bottom: 6px;
}

.a41 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.a1r\[22px\] {
  padding-left: 22px;
  padding-right: 22px;
}

.a47 {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

.a4e {
  padding-left: .5rem;
  padding-right: .5rem;
}

.aY {
  padding-left: .75rem;
}

.a10 {
  padding-top: 1.25rem;
}

.a1t\[150px\] {
  padding-top: 150px;
}

.a1u\[110px\] {
  padding-bottom: 110px;
}

.a1t\[95px\] {
  padding-top: 95px;
}

.a1u\[46px\] {
  padding-bottom: 46px;
}

.a1u\[60px\] {
  padding-bottom: 60px;
}

.a2O {
  padding-top: 2.5rem;
}

.a1u\[70px\] {
  padding-bottom: 70px;
}

.a1t\[165px\] {
  padding-top: 165px;
}

.a32\[18px\] {
  padding-right: 18px;
}

.a36 {
  padding-top: 2rem;
}

.a1t\[110px\] {
  padding-top: 110px;
}

.a3e {
  padding-top: 3.5rem;
}

.a3f {
  padding-bottom: 3.5rem;
}

.a3l {
  padding-bottom: 1.5rem;
}

.a1t\[100px\] {
  padding-top: 100px;
}

.a16 {
  padding-bottom: 1.25rem;
}

.a1t\[30px\] {
  padding-top: 30px;
}

.a3J {
  padding-bottom: 2.5rem;
}

.a3h {
  padding-top: 5rem;
}

.a3R {
  padding-left: 1rem;
}

.a3S {
  padding-right: 1.25rem;
}

.a3Y {
  padding-bottom: 2.25rem;
}

.a1t\[70px\] {
  padding-top: 70px;
}

.a1u\[50px\] {
  padding-bottom: 50px;
}

.a17 {
  text-align: left;
}

.aF {
  text-align: center;
}

.aR {
  font-size: 1rem;
  line-height: 1.5rem;
}

.a1A\[28px\] {
  font-size: 28px;
}

.a1A\[22px\] {
  font-size: 22px;
}

.a2o {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.a2C {
  font-size: .875rem;
  line-height: 1.25rem;
}

.a1C {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.a2U {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.blog-heading {
  text-align: center;
  font-size: .5rem;
  line-height: 2.25rem;
}

.a1A\[40px\] {
  font-size: 40px;
}

.a3T {
  font-size: .75rem;
  line-height: 1rem;
}

.a2p {
  font-size: 1.5rem;
  line-height: 2rem;
}

.at {
  font-weight: 700;
}

.aS {
  font-weight: 500;
}

.a2e {
  font-weight: 600;
}

.a2f {
  line-height: 1.25;
}

.a2-M {
  line-height: .75;
}

.a2Z {
  line-height: 1.625;
}

.a3I {
  line-height: 1;
}

.aT {
  --tw-text-opacity: 1;
  color: #13005a;
}

.aV {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.aV1 {
  --tw-text-opacity: 1;
  color: #13005a;
}

.a1B {
  --tw-text-opacity: 1;
  color: rgb(121 128 138 / var(--tw-text-opacity));
}

.a2Y {
  color: #0000;
}

.aU {
  color: #13005a;
}

.a3L {
  text-decoration-line: underline;
}

.a1Y {
  opacity: .7;
}

.a44\[65\%\] {
  opacity: .65;
}

.a44Z {
  opacity: .6;
}

.a22 {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.a19 {
  --tw-shadow: 0px 1px 5px #2d4aaa24;
  --tw-shadow-colored: 0px 1px 5px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.a45 {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.a2P {
  --tw-drop-shadow: drop-shadow(0px 1px 5px #2d4aaa24);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.aG {
  --tw-backdrop-blur: blur(4px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.a9 {
  transition-duration: .3s;
}

.a23 {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.sticky-navbar {
  --tw-bg-opacity: .8;
  --tw-backdrop-blur: blur(4px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  border-bottom-width: 1px;
  position: fixed;
}

.a4r .sticky-navbar {
  --tw-bg-opacity: .8;
}

.sticky-navbar .menu-scroll.active, .menu-scroll.active, .a4r .sticky-navbar .menu-scroll.active, .a4r .menu-scroll.active {
  --tw-text-opacity: 1;
  color: rgb(112 131 245 / var(--tw-text-opacity));
}

@media (min-width: 992px) {
  .sticky-navbar ul > .menu-item > a {
    padding-top: 21px;
    padding-bottom: 21px;
  }
}

.submenu-item a.active {
  --tw-text-opacity: 1;
  color: rgb(112 131 245 / var(--tw-text-opacity));
}

.a4r .submenu-item a.active {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.submenu-item a.active span svg {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media (min-width: 992px) {
  .submenu-item a.active span svg {
    --tw-rotate: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

.container {
  width: 100%;
}

@media (min-width: 575px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

.keep-signed:checked ~ span.box {
  --tw-border-opacity: 1;
  border-color: rgb(112 131 245 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(112 131 245 / var(--tw-bg-opacity));
}

.keep-signed:checked ~ span.box .icon {
  display: block;
}

#togglePlan:checked ~ span .dot {
  --tw-translate-x: 26px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.faq .faq-btn:after {
  content: "+";
  --tw-text-opacity: 1;
  color: rgb(24 28 49 / var(--tw-text-opacity));
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
}

.a4r .faq .faq-btn:after {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.faq.active .faq-btn:after {
  content: "-";
}

.faq .faq-content {
  max-height: 0;
  opacity: 0;
  padding-top: 0;
  padding-bottom: 0;
  transition-property: all;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.faq.active .faq-content {
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  opacity: 1;
  padding-top: 30px;
  padding-bottom: 30px;
}

.swiper-button-prev, .swiper-button-next {
  height: 2.5rem;
  width: 2.5rem;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(121 128 138 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(121 128 138 / var(--tw-text-opacity));
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: static;
  top: auto;
  bottom: 0;
}

.swiper-button-prev:after, .swiper-button-next:after {
  content: var(--tw-content);
  display: none;
}

.swiper-button-prev:hover, .swiper-button-next:hover {
  --tw-border-opacity: 1;
  border-color: rgb(112 131 245 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(112 131 245 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.last-of-type\:a40:last-of-type {
  border-style: none;
}

.hover\:a1q:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(112 131 245 / var(--tw-bg-opacity));
}

.hover\:a1s:hover {
  --tw-bg-opacity: .9;
}

.hover\:a24:hover {
  --tw-bg-opacity: .8;
}

.hover\:aU:hover {
  --tw-text-opacity: 1;
  color: #03c988;
}

.hover\:aV:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:a3L:hover {
  text-decoration-line: underline;
}

.hover\:a1c:hover {
  opacity: 1;
}

.focus\:a46:focus {
  --tw-border-opacity: 1;
  border-color: rgb(112 131 245 / var(--tw-border-opacity));
}

.focus\:a4c:focus {
  --tw-shadow: 0px 10px 30px #4a6cf714;
  --tw-shadow-colored: 0px 10px 30px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.aX:hover .group-hover\:a1q {
  --tw-bg-opacity: 1;
  background-color: rgb(112 131 245 / var(--tw-bg-opacity));
}

.aX:hover .group-hover\:aU {
  --tw-text-opacity: 1;
  color: rgb(112 131 245 / var(--tw-text-opacity));
}

.aX:hover .group-hover\:aV {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.a4r .dark\:ah {
  display: block;
}

.a4r .dark\:ak {
  display: none;
}

.a4r .dark\:aa {
  --tw-border-opacity: 1;
  border-color: rgb(52 55 74 / var(--tw-border-opacity));
}

.a4r .dark\:a33\[\#BDBDBD\] {
  --tw-border-opacity: 1;
  border-color: rgb(189 189 189 / var(--tw-border-opacity));
}

.a4r .dark\:a33\[\#34374A\] {
  --tw-border-opacity: 1;
  border-color: rgb(52 55 74 / var(--tw-border-opacity));
}

.a4r .dark\:a2 {
  --tw-bg-opacity: 1;
  background-color: rgb(24 28 49 / var(--tw-bg-opacity));
}

.a4r .dark\:a1 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.a4r .dark\:a1e\[\#15182A\] {
  --tw-bg-opacity: 1;
  background-color: rgb(21 24 42 / var(--tw-bg-opacity));
}

.a4r .dark\:a2Q {
  --tw-bg-opacity: 1;
  background-color: rgb(31 35 58 / var(--tw-bg-opacity));
}

.a4r .dark\:a1e\[\#2A2E44\] {
  --tw-bg-opacity: 1;
  background-color: rgb(42 46 68 / var(--tw-bg-opacity));
}

.a4r .dark\:a1e\[\#15182B\] {
  --tw-bg-opacity: 1;
  background-color: rgb(21 24 43 / var(--tw-bg-opacity));
}

.a4r .dark\:a4b {
  --tw-bg-opacity: 1;
  background-color: rgb(52 55 74 / var(--tw-bg-opacity));
}

.a4r .dark\:aE {
  --tw-bg-opacity: .95;
}

.a4r .dark\:aV {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.a4r .dark\:aT {
  --tw-text-opacity: 1;
  color: #13005a;
}

.a4r .dark\:a1B {
  --tw-text-opacity: 1;
  color: rgb(121 128 138 / var(--tw-text-opacity));
}

.a4r .dark\:a1f {
  --tw-shadow: 0px 1px 5px #10193724;
  --tw-shadow-colored: 0px 1px 5px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.a4r .dark\:a2R {
  --tw-drop-shadow: drop-shadow(0px 1px 5px #10193724);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.a4r .dark\:hover\:a1q:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(112 131 245 / var(--tw-bg-opacity));
}

.a4r .dark\:hover\:a1s:hover {
  --tw-bg-opacity: .9;
}

.a4r .dark\:hover\:aU:hover {
  --tw-text-opacity: 1;
  color: rgb(112 131 245 / var(--tw-text-opacity));
}

.a4r .dark\:focus\:a46:focus {
  --tw-border-opacity: 1;
  border-color: rgb(112 131 245 / var(--tw-border-opacity));
}

.a4r .aX:hover .dark\:group-hover\:a1q {
  --tw-bg-opacity: 1;
  background-color: rgb(112 131 245 / var(--tw-bg-opacity));
}

@media (min-width: 575px) {
  .sm\:play {
    height: 128px;
    width: 128px;
    border-width: .5rem;
  }

  .sm\:play svg {
    margin-left: .7rem;
  }

  .sm\:a1W {
    margin-bottom: 0;
  }

  .sm\:ah {
    display: block;
  }

  .sm\:a1o {
    display: inline-block;
  }

  .sm\:ae {
    display: flex;
  }

  .sm\:a1L\/2 {
    width: 50%;
  }

  .sm\:av\/12 {
    width: 58.3333%;
  }

  .sm\:a1I\/12 {
    width: 41.6667%;
  }

  .sm\:a1L\/3 {
    width: 33.3333%;
  }

  .sm\:ad\[180px\] {
    max-width: 180px;
  }

  .sm\:a1i {
    justify-content: flex-end;
  }

  .sm\:aN > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:a3A\[50px\] {
    padding: 50px;
  }

  .sm\:a3O {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .sm\:a3F {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .sm\:a1r\[26px\] {
    padding-left: 26px;
    padding-right: 26px;
  }

  .sm\:a2S {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:a2D {
    text-align: right;
  }

  .sm\:a1A\[35px\] {
    font-size: 35px;
  }

  .blog-body {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .sm\:a1C {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .sm\:a1A\[32px\] {
    font-size: 32px;
  }

  .sm\:a2p {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .sm\:a1A\[22px\] {
    font-size: 22px;
  }

  .sm\:a1A\[40px\] {
    font-size: 40px;
  }

  .sm\:a3c {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .sm\:a1A\[28px\] {
    font-size: 28px;
  }
}

@media (min-width: 768px) {
  .md\:a2G {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }

  .md\:a1W {
    margin-bottom: 0;
  }

  .md\:ah {
    display: block;
  }

  .md\:a1M\/12 {
    width: 16.6667%;
  }

  .md\:a1N\/12 {
    width: 25%;
  }

  .md\:a1H\/12 {
    width: 33.3333%;
  }

  .md\:a1L\/2 {
    width: 50%;
  }

  .md\:a1L\/3 {
    width: 33.3333%;
  }

  .md\:a13\[700px\] {
    width: 700px;
  }

  .md\:a1L\/4 {
    width: 25%;
  }

  .md\:a1X {
    justify-content: flex-start;
  }

  .md\:a1i {
    justify-content: flex-end;
  }

  .md\:a2w {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:a2J {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .md\:a3g {
    padding-bottom: .25rem;
  }

  .md\:a1A\[50px\] {
    font-size: 50px;
  }

  .md\:a1A\[44px\] {
    font-size: 44px;
  }

  .md\:a1A\[22px\] {
    font-size: 22px;
  }

  .md\:a1A\[38px\] {
    font-size: 38px;
  }

  .md\:a2f {
    line-height: 1.25;
  }

  .md\:play {
    max-height: 192px;
    max-width: 192px;
    border-width: .75rem;
  }

  .sm\:play svg {
    margin-left: 1rem;
  }
}

@media (min-width: 992px) {
  .lg\:a11 {
    visibility: hidden;
  }

  .lg\:aH {
    position: static;
  }

  .lg\:a3 {
    position: absolute;
  }

  .lg\:a12\[120\%\] {
    top: 120%;
  }

  .lg\:a1R {
    order: -9999;
  }

  .lg\:a1P {
    order: 9999;
  }

  .lg\:a2H {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .lg\:a3m {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:a1j {
    margin-right: 0;
  }

  .lg\:a1T {
    margin-top: 0;
  }

  .lg\:a1W {
    margin-bottom: 0;
  }

  .lg\:a1z\[70px\] {
    margin-bottom: 70px;
  }

  .lg\:a3t {
    margin-left: auto;
  }

  .lg\:a1U {
    margin-bottom: 1rem;
  }

  .lg\:a3Q {
    margin-left: 0;
  }

  .lg\:ah {
    display: block;
  }

  .lg\:ae {
    display: flex;
  }

  .lg\:ak {
    display: none;
  }

  .lg\:aI {
    height: auto;
  }

  .lg\:aJ {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }

  .lg\:a13\[250px\] {
    width: 250px;
  }

  .lg\:a1H\/12 {
    width: 33.3333%;
  }

  .lg\:a1K\/12 {
    width: 66.6667%;
  }

  .lg\:a1M\/12 {
    width: 16.6667%;
  }

  .lg\:a1N\/12 {
    width: 25%;
  }

  .lg\:a1L\/3 {
    width: 33.3333%;
  }

  .lg\:av\/12 {
    width: 58.3333%;
  }

  .lg\:a1I\/12 {
    width: 41.6667%;
  }

  .lg\:a1L\/2 {
    width: 50%;
  }

  .lg\:a1L\/6 {
    width: 16.6667%;
  }

  .lg\:ad\[1250px\] {
    max-width: 1250px;
  }

  .lg\:ad\[1305px\] {
    max-width: 1305px;
  }

  .lg\:ad\[570px\] {
    max-width: 570px;
  }

  .lg\:ad\[1120px\] {
    max-width: 1120px;
  }

  .lg\:ad\[510px\] {
    max-width: 510px;
  }

  .lg\:ad\[1160px\] {
    max-width: 1160px;
  }

  .lg\:ad\[1200px\] {
    max-width: 1200px;
  }

  .lg\:a1X {
    justify-content: flex-start;
  }

  .lg\:a1i {
    justify-content: flex-end;
  }

  .lg\:ar {
    justify-content: center;
  }

  .lg\:aN > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:aO > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .lg\:a14 {
    border-radius: .5rem;
  }

  .lg\:aK {
    background-color: #0000;
  }

  .lg\:a1 {
    --tw-bg-opacity: 1;
    background-color: #aff8db;
  }

  .lg\:aj {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:aW {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .lg\:a15 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:a2K {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .lg\:a2S {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:a2J {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .lg\:a2w {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .lg\:a16 {
    padding-bottom: 1.25rem;
  }

  .lg\:a13 {
    padding-bottom: 5rem;
  }

  .lg\:a1t\[220px\] {
    padding-top: 220px;
  }

  .lg\:a3h {
    padding-top: 5rem;
  }

  .lg\:a17 {
    text-align: left;
  }

  .lg\:a2o {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .lg\:a1A\[42px\] {
    font-size: 42px;
  }

  .lg\:a18 {
    opacity: 0;
  }

  .lg\:a19 {
    --tw-shadow: 0px 1px 5px #2d4aaa24;
    --tw-shadow-colored: 0px 1px 5px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:aL {
    --tw-backdrop-blur: blur(0);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .aX:hover .lg\:group-hover\:a1a {
    visibility: visible;
  }

  .aX:hover .lg\:group-hover\:a1b {
    top: 100%;
  }

  .aX:hover .lg\:group-hover\:a1c {
    opacity: 1;
  }

  .a4r .dark\:lg\:a1d {
    border-color: #0000;
  }

  .a4r .lg\:dark\:aK {
    background-color: #0000;
  }

  .a4r .dark\:lg\:a1e\[\#15182A\] {
    --tw-bg-opacity: 1;
    background-color: rgb(21 24 42 / var(--tw-bg-opacity));
  }

  .a4r .lg\:dark\:a1f {
    --tw-shadow: 0px 1px 5px #10193724;
    --tw-shadow-colored: 0px 1px 5px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:play {
    height: 256px;
    width: 256px;
    border-width: 1rem;
  }
}

@media (min-width: 1200px) {
  .xl\:a2I\[35px\] {
    margin-left: -35px;
    margin-right: -35px;
  }

  .xl\:a1W {
    margin-bottom: 0;
  }

  .xl\:ae {
    display: flex;
  }

  .xl\:a1I\/12 {
    width: 41.6667%;
  }

  .xl\:av\/12 {
    width: 58.3333%;
  }

  .xl\:a1i {
    justify-content: flex-end;
  }

  .xl\:aP > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .xl\:a1r\[35px\] {
    padding-left: 35px;
    padding-right: 35px;
  }

  .xl\:a2S {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .xl\:a3F {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .xl\:a3O {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .xl\:a2p {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .xl\:a1A\[22px\] {
    font-size: 22px;
  }

  .xl\:a1A\[50px\] {
    font-size: 50px;
  }

  .xl\:a1A\[26px\] {
    font-size: 26px;
  }
}

@media (min-width: 450px) {
  .xs\:ad\[265px\] {
    max-width: 265px;
  }
}

.ml-2 {
  margin-left: 2rem;
}

.dt-96 {
  top: -96px;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mt-5 {
  margin-top: 5rem;
}

.small-circle {
  width: 80%;
  left: 10%;
  right: 10%;
}

.o-1 {
  opacity: 1;
}

.logo {
  width: 6rem;
}

.highlight {
  color: #79808a;
  background: none;
  padding: 2px;
  display: inline-block;
  position: relative;
}

.highlight:before {
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: linear-gradient(to right, #85e3ff 0%, #aff8db 51%, #fff5ba 100%);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-2.22deg);
}

.a2W1 {
  background-color: #13005a;
}

.btn-gradient {
  --tw-shadow: 0px 1px 5px #2d4aaa24;
  --tw-shadow-colored: 0px 1px 5px var(--tw-shadow-color);
  will-change: opacity;
  opacity: 1;
  text-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: #13005a;
  transition: opacity .2s ease-out;
}

.greenbtn-gradient {
  --tw-shadow: 0px 1px 5px #2d4aaa24;
  --tw-shadow-colored: 0px 1px 5px var(--tw-shadow-color);
  opacity: 1;
  text-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  color: #595959;
  background-image: linear-gradient(to right, #85e3ff 0%, #aff8db 51%, #fff5ba 100%);
  transition: all 1s ease-out;
}

.btn-gradient:hover {
  opacity: .8;
  background-color: #03c988;
}

.greenbtn-gradient:hover {
  opacity: 1;
  background-color: #13005a;
}

.abdc:hover {
  color: #0037b9;
}

.play svg {
  margin-left: .25rem;
}

handwriting {
  color: #03c988;
  font-family: cursive;
  font-size: 60px;
}

.box_body {
  max-width: 100%;
  grid-template-columns: 300px 1fr;
  align-items: center;
  gap: 10rem;
  margin: 0 auto;
  padding: 2rem 2rem 2rem 10%;
  font: 500 large / 1.5 system-ui;
  display: grid;
}

.box_img {
  max-width: 100%;
  width: 1200px;
}

@media (max-width: 650px) {
  .box_body {
    font-size: 80%;
    display: block;
  }

  .box_img {
    background: rgba(white, .8);
    margin: -3rem 0 2rem 1rem;
    padding: 1rem;
    position: relative;
  }

  .a2-M {
    line-height: 1.1;
  }
}

/*# sourceMappingURL=create-high-quality-content-to-drive-conversions.7bc5f262.css.map */
